
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import UpdateUi from "@/components/Core/Update/UpdateUI.vue";
import EquipmentBrandAutocomplete from "@/components/General/Autocompletes/Equipments/EquipmentBrandAutocomplete.vue";

import { UtProbeItem } from "prometheus-synced-ui";

import { IUtProbeService } from "@/services";

@Component({
  components: {
    UpdateUi,
    EquipmentBrandAutocomplete,
  },
})
export default class UtProbeUpdate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  item!: UtProbeItem;

  @Prop({ required: true })
  forbiddenSerialNumbers!: string[];

  get utProbeService() {
    return this.container.resolve<IUtProbeService>(S.UT_PROBE_SERVICE);
  }

  loading: boolean = false;
  serialNumber: string | null = null;
  equipmentType: string | null = null;
  equipmentBrandId: string | null = null;
  pvNumber: string | null = null;
  diameter: number | null = null;
  frequency: number | null = null;

  mounted() {
    this.serialNumber = this.item.serialNumber;
    this.equipmentType = this.item.equipmentType;
    this.equipmentBrandId = this.item.equipmentBrand.id;
    this.pvNumber = this.item.pvNumber;
    this.diameter = this.item.diameter;
    this.frequency = this.item.frequency;
  }

  isAllowed(serialNumber: string) {
    return (
      _.findIndex(
        this.forbiddenSerialNumbers,
        (v) => v.toUpperCase() == serialNumber.toUpperCase()
      ) != -1 &&
      this.item.serialNumber.toUpperCase() != serialNumber.toUpperCase()
    );
  }

  async update() {
    try {
      this.loading = true;
      const updatedProbe = await this.utProbeService.update(this.item.id, {
        serialNumber: this.serialNumber!,
        equipmentType: this.equipmentType!,
        equipmentBrandId: this.equipmentBrandId!,
        diameter: this.diameter!,
        frequency: this.frequency!,
        pvNumber: this.pvNumber!,
        disabled: this.item.disabled,
      });
      this.$emit("update", updatedProbe);
    } finally {
      this.loading = false;
    }
  }
}
